<template>
  <section
    class="
      bg-white
      sm:text-left
      shadow-xl
      rounded-xl
      sm:p-14 sm:px-14 sm:w-full sm:p-10
      py-10
      px-4
      "
    style="height: 500px;"
  >
    <h3 class="sm:text-xl font-bold text-base">{{ title }}</h3>
    <h5 v-if="subtitle" class="sm:text-sm text-sm text-left">
      {{ subtitle }}
    </h5>
    <apexchart
      type="bar"
      height="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>
<script>
export default {
  // Datos de para el eje de las ordenadas y el eje de las axisas
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },

    datax: {
      type: [],
      required: true,
    },
    datay: {
      type: [],
      required: true,
    },
    distributed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
         noData: {
          text: 'No hay datos para graficar',
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "24px",
            fontFamily: undefined,
          },
        },
        plotOptions: {
          bar: {
            distributed: false,
            borderRadius: 15,
            horizontal: undefined,
            dataLabels: {
              position: "top",
             
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "13px",
            colors: ["#fff"],
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "7px",
                  },
                },
              },
            },
          },
          {
            breakpoint: 300,
            options: {
              chart: {
                height: "100%",
                width: "100%",
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 5,
                },
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
              yaxis: [
                {
                  title: {
                    text: "",
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: "",
                  },
                },
              ],
            },
          },
        ],
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "10px",
            },
          },
        },

        theme: {
          monochrome: {
            enabled: true,
            color: "#3366cc",
            shadeTo: "dark",
            shadeIntensity: 0.8,
          },
        },
      },
    };
  },
  created() {
    this.chartOptions.xaxis.categories = this.datax;
    this.series = this.datay;
    this.chartOptions.plotOptions.bar.distributed = this.distributed;
    this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
    this.chartOptions.dataLabels.offsetX = this.horizontal ? -20 : 0;
    this.chartOptions.dataLabels.offsetY = !this.horizontal ? 20 : 0;
  },
};
</script>
<style scoped>
.card {
  height: 30em;
}
</style>